export const startMap = () => {
  const map = document.querySelector('.map');
  if (!map) {
    return;
  }

  map.addEventListener('click', (evt) => {
    const target = evt.target.classList.contains('point')
      ? evt.target
      : evt.target.closest('.point');
    document.querySelector('.point.active')?.classList.remove('active');
    if (!target) {
      hidePointModal();
      return;
    }
    const title = target.textContent;
    const imsSrc = target.dataset.src;
    const description = target.dataset.description;
    const link = target.dataset.link;

    target.classList.add('active');
    showPointModal(title, imsSrc, description, link);
  });

  const mapContainer = document.querySelector('.map__container');
  const scalePlusButton = document.querySelector('.map__scale--plus');
  const scaleMinusButton = document.querySelector('.map__scale--minus');
  const MIN_SCALE = 1;
  const MAX_SCALE = 3;
  const SCALE_STEP = 0.2;
  let currentScale = 1;
  const TRANSLATE_X_STEP = window.matchMedia('(max-width: 430px)').matches
    ? 6
    : 4; // Проценты
  const TRANSLATE_Y_STEP = 6; // Проценты
  const TRANSLATE_MULTIPLY_STEP = 0.9;
  let currentTranslateX = 0;
  let currentTranslateY = 0;
  scalePlusButton.addEventListener('click', () => {
    if (currentScale + SCALE_STEP <= MAX_SCALE) {
      currentScale += SCALE_STEP;
      const multipliedCurrentScale = currentScale * TRANSLATE_MULTIPLY_STEP;
      const translateFactor =
        multipliedCurrentScale < 1 ? 1 : multipliedCurrentScale;
      currentTranslateX += TRANSLATE_X_STEP / translateFactor;
      currentTranslateY += TRANSLATE_Y_STEP / translateFactor;
      mapContainer.style.scale = `${currentScale}`;
      mapContainer.style.transform = `translate(${currentTranslateX}%, ${currentTranslateY}%)`;
    }
  });

  scaleMinusButton.addEventListener('click', () => {
    if (currentScale - SCALE_STEP >= MIN_SCALE) {
      currentScale -= SCALE_STEP;
      const multipliedCurrentScale = currentScale * TRANSLATE_MULTIPLY_STEP;
      const translateFactor =
        multipliedCurrentScale < 1 ? 1 : multipliedCurrentScale;
      currentTranslateX -= TRANSLATE_X_STEP / translateFactor;
      currentTranslateY -= TRANSLATE_Y_STEP / translateFactor;
      mapContainer.style.scale = `${currentScale}`;
      mapContainer.style.transform = `translate(${
        currentTranslateX < 0 ? 0 : currentTranslateX
      }%, ${currentTranslateY < 0 ? 0 : currentTranslateY}%)`;
    }
  });

  const closeButton = document.querySelector('.point-modal__close-button');

  closeButton.addEventListener('click', () => {
    hidePointModal();
  });

  let isDragging = false;
  let startX;
  let startY;
  let scrollLeft;
  let scrollTop;

  mapContainer.addEventListener('mousedown', (e) => {
    isDragging = true;
    startX = e.pageX - mapContainer.offsetLeft;
    startY = e.pageY - mapContainer.offsetTop;
    scrollLeft = map.scrollLeft;
    scrollTop = map.scrollTop;
  });

  mapContainer.addEventListener('mouseup', () => {
    isDragging = false;
  });

  mapContainer.addEventListener('mouseleave', () => {
    isDragging = false;
  });

  const throttle = (func, limit) => {
    let inThrottle;
    return function () {
      const args = arguments;
      if (inThrottle) {
        return;
      }
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    };
  };

  mapContainer.addEventListener(
    'mousemove',
    throttle((e) => {
      if (!isDragging) {
        return;
      }
      e.preventDefault();
      const x = e.pageX - mapContainer.offsetLeft;
      const y = e.pageY - mapContainer.offsetTop;
      const walkX = x - startX;
      const walkY = y - startY;
      map.scrollLeft = scrollLeft - walkX;
      map.scrollTop = scrollTop - walkY;
    })
  );
};

const showPointModal = (title, imgSrc, description, link) => {
  document.querySelector('.point-modal').classList.add('show');
  const pointModal = document.querySelector('.point-modal');
  pointModal.querySelector('.point-modal__title').textContent = title;
  pointModal.querySelector('.point-modal__img').src = imgSrc;
  pointModal.querySelector('.point-modal__text').textContent = description;
  const linkElement = pointModal.querySelector('.point-modal__link');
  if (link) {
    linkElement.classList.remove('hide');
    linkElement.href = link;
  } else {
    linkElement.classList.add('hide');
  }
};

const hidePointModal = () => {
  document.querySelector('.point-modal').classList.remove('show');
};
