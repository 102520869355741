import {
  closeModalWindow,
  openModalWindow,
  addModalStyle,
} from '../plugins/penguin-modal.js';
import { showModal } from '../plugins/penguin-alerts.js';

const bookingForms = document.querySelectorAll('.booking__form');
const lineBookingForms = document.querySelectorAll('.line-booking');
const ContactBookingForms = document.querySelectorAll('.booking-contact__form');

export const startBooking = () => {
  const contactModalForm = document.querySelector('#booking-contact');
  if (contactModalForm) {
    addModalStyle(contactModalForm);
  }

  if (bookingForms.length > 0) {
    bookingForms.forEach((form) => {
      form.addEventListener('submit', (evt) => {
        evt.preventDefault();
        const data = new FormData(evt.target);
        fetch('/wp-content/themes/marula/mail.php', {
          method: 'POST',
          body: data,
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error('Ошибка запроса');
            }
            return res.json();
          })
          .then((data) => {
            if (data.status == 'success') {
              closeModalWindow(document.querySelector('.c-modal.active'));
              clearInputs(form);
              showModal({
                iconType: 'success',
                titleText: 'Everything is good',
                text: 'Your application has been sent',
                buttonText: 'OK',
              });
            }
          });
      });
    });
  }

  if (lineBookingForms.length > 0) {
    lineBookingForms.forEach((lineForm) => {
      lineForm.addEventListener('submit', (evt) => {
        evt.preventDefault();
        openModalWindow(contactModalForm);
        ContactBookingForms.forEach((form) => {
          form.addEventListener('submit', (evt) => {
            evt.preventDefault();
            const firstForm = document.querySelector('.line-booking');

            // Создаем новый объект FormData
            const data = new FormData();

            // Добавляем данные из формы form1 в объект formData
            for (const entry of new FormData(firstForm)) {
              data.append(entry[0], entry[1]);
            }
            for (const entry of new FormData(evt.target)) {
              data.append(entry[0], entry[1]);
            }
            fetch('/wp-content/themes/marula/mail.php', {
              method: 'POST',
              body: data,
            })
              .then((res) => {
                if (!res.ok) {
                  throw new Error('Ошибка запроса');
                }
                return res.json();
              })
              .then((data) => {
                if (data.status == 'success') {
                  closeModalWindow(document.querySelector('.c-modal.active'));
                  clearInputs(form);
                  clearInputs(lineForm);
                  showModal({
                    iconType: 'success',
                    titleText: 'Everything is good',
                    text: 'Your application has been sent',
                    buttonText: 'OK',
                  });
                }
              });
          });
        });
      });
    });
  }
};

const clearInputs = (form) => {
  const inputs = form.querySelectorAll('input');
  inputs.forEach((input) => {
    input.value = input.classList.contains('quantity-num') ? 1 : '';
  });
};
