import { startBooking } from './sections/booking.js';
import { startModals } from './plugins/penguin-modal.js';
import { quantityProducts } from './sections/quantity.js';
import { startAllFilers } from './plugins/penguin-filter';
import { startLodgesMenu } from './sections/lodges-menu.js';
import { startCustomSplide } from './sections/splide-custom.js';
import { fixTextBlock } from './sections/text-block.js';
import { startMap } from './sections/map.js';

document.addEventListener('DOMContentLoaded', () => {
  startBooking();
  startModals();
  quantityProducts();
  startAllFilers();
  startLodgesMenu();
  startCustomSplide();
  fixTextBlock();
  startMap();
});
