const telFilter = () => {
  const inputs = document.querySelectorAll('.tel-input');

  inputs.forEach((input) => {
    input.addEventListener('input', (evt) => {
      const target = evt.target;
      const allowedChars = /^\+?\d*$/;
      if (!allowedChars.test(target.value)) {
        target.value = target.value.slice(0, -1);
      }
    });
  });
};

export const startAllFilers = () => {
  telFilter();
};
