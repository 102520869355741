export const startLodgesMenu = () => {
  const openButtons = document.querySelectorAll('.lodges__open-button');
  

  openButtons.forEach((openButton) => {
    const lodges = openButton.closest('li').querySelector('.lodges');
    openButton.addEventListener('click', () => {
      lodges.classList.toggle('open');
    });
  });
};
