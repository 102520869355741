export const quantityProducts = () => {
  const quantityArrowMinus = document.querySelectorAll('.quantity-arrow-minus');
  const quantityArrowPlus = document.querySelectorAll('.quantity-arrow-plus');

  quantityArrowMinus.forEach((arrow) => {
    arrow.addEventListener('click', quantityMinus);
  });

  quantityArrowPlus.forEach((arrow) => {
    arrow.addEventListener('click', quantityPlus);
  });

  function quantityMinus(evt) {
    evt.preventDefault();
    const oldValue = evt.target
      .closest('.quantity-block')
      .querySelector('.quantity-num').value;
    if (oldValue > 1) {
      evt.target
        .closest('.quantity-block')
        .querySelector('.quantity-num').value = oldValue - 1;
    }
  }

  function quantityPlus(evt) {
    evt.preventDefault();
    const oldValue = evt.target
      .closest('.quantity-block')
      .querySelector('.quantity-num').value;
    evt.target.closest('.quantity-block').querySelector('.quantity-num').value =
      oldValue - 0 + 1;
  }
};
