export const startCustomSplide = () => {
  const circleContainers = document.querySelectorAll('.splide__slide');

  if (circleContainers.length > 0) {
    circleContainers.forEach((circleContainer) => {
      const element = circleContainer.querySelector('.circle');
      circleContainer.addEventListener('mouseover', (e) => {
        element.classList.add('active');
      });

      circleContainer.addEventListener('mousemove', (e) => {
        const elementSize = element.offsetWidth;
        const xOffset = -30;

        const containerX = circleContainer.getBoundingClientRect().left;
        const containerY = circleContainer.getBoundingClientRect().top;

        const mouseX = e.clientX - containerX;
        const mouseY = e.clientY - containerY;

        const elementX = mouseX - elementSize / 2 + xOffset;
        const elementY = mouseY - elementSize / 2;
        element.style.transform = `translate(${elementX}px, ${elementY}px)`;
      });

      circleContainer.addEventListener('mouseout', (e) => {
        element.classList.remove('active');
      });
    });
  }
};
