export const fixTextBlock = () => {
  const textBlocks = document.querySelectorAll('.text-block__container div');

  if (textBlocks.length > 0) {
    textBlocks.forEach((textBlock) => {
      if (textBlock.querySelectorAll('p').length <= 0) {
        textBlock.style.display = 'none';
      }
    });
  }

  const lodgesRoomsElement = document.querySelector('.lodges-rooms');
  if (lodgesRoomsElement) {
    if (lodgesRoomsElement.previousElementSibling) {
      lodgesRoomsElement.previousElementSibling.style.minHeight = '0';
    }

    if (lodgesRoomsElement.querySelectorAll('.grid div').length <= 0) {
      lodgesRoomsElement.style.display = 'none';
    }
  }
};
